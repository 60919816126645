/* global Swipe, Ten4Slideshow */
import '../vendor/slideshow.js';
import Swipe from '../vendor/swipe.js';
import VideoPlayer from '../vendor/videoplayer.js';
import { getWindowWidth } from '../helpers.js';
import { breakpoints } from '../../config.yml';

export function buildSlideshows() {
	// Slideshows
	const el = document.querySelector( '.slideshow' );
	if ( !el ) {
		return;
	}

	// basic vars
	const slideshow_switch_width = breakpoints.d.width;
	const slide_interval = parseInt( el.getAttribute( 'data-slide-interval' ), 10 );
	const slides = el.querySelectorAll( '.slideshow__slide' );
	const slide_data = [];
	let timer_id = null;

	let slideshow_obj = { type: null, slideshow: null };
	build( getWindowWidth(), true );
	setupSlides( slides );
	startSlideshow();
	window.addEventListener( 'resize', () => {
		resizeVideos( slide_data.filter( item => item.type === 'video' ) );
		build( getWindowWidth() );
	} );

	function resizeVideos( els ) {
		for ( let i = 0; i < els.length; i++ ) {
			els[i].item.resizeVideo();
		}
	}

	function build( window_width, first_run = false ) {
		if ( window_width > slideshow_switch_width ) {
			if ( slideshow_obj.type !== 'Ten4Slideshow' ) {
				if ( slideshow_obj.type === 'Swipe' ) {
					slideshow_obj.slideshow.kill();
				}
				const new_slideshow = new Ten4Slideshow( el );
				new_slideshow.addCallback( handleTen4SlideChange( new_slideshow ) );
				slideshow_obj = {
					type: 'Ten4Slideshow',
					slideshow: new_slideshow
				};
				if ( !first_run ) {
					startSlideshow();
				}
			}
			return;
		}
		if ( slideshow_obj.type !== 'Swipe' ) {
			if ( slideshow_obj.type === 'Ten4Slideshow' ) {
				clearInterval();
			}
			const swipe = new Swipe( el, {
				speed: 400,
				auto: slide_interval,
				draggable: true,
				continuous: true,
				callback: function( index, elem ) {
					if ( slide_data[index].type === 'video' ) {
						slide_data[index].item.play();
						swipe.stop();
						return;
					}
					swipe.restart();
					updateLogoColour( elem );
				}
			} );
			slideshow_obj = {
				type: 'Swipe',
				slideshow: swipe
			};
			if ( !first_run ) {
				startSlideshow();
			}
		}
	}
	function handleTen4SlideChange( slideshow ) {
		return ( index ) => {
			const current_slide = slide_data[index];
			if ( current_slide.type === 'video' && !current_slide.item.isDisabled() && ( getWindowWidth() >= 699 ) ) {
				clearInterval();
				current_slide.item.play();
			} else {
				resetInterval();
			}
			updateLogoColour( slideshow.slides[index] );

			const prev_slide = slide_data[slideshow_obj.slideshow.top_slide_i];
			if ( prev_slide.type === 'video' && !prev_slide.item.isDisabled() ) {
				setTimeout( () => {
					prev_slide.item.reset();
				}, 50 );
			}
		};
	}

	function updateLogoColour( slide_element ) {
		const current_slide_logo_colour = slide_element.getAttribute( 'data-slide-logo-colour' );
		const header_logo = document.querySelector( '.header__logo' );
		const header_logo_classes = header_logo.className.split( ' ' );

		const regex = RegExp( '^header__logo--.+$' );
		for ( let i = 0; i < header_logo_classes.length; i++ ) {
			if ( regex.test( header_logo_classes[i] ) ) {
				header_logo.classList.remove( header_logo_classes[i] );
			}
		}

		header_logo.classList.add( 'header__logo--' + current_slide_logo_colour );
	}

	function clearInterval() {
		window.clearInterval( timer_id );
	}
	function resetInterval() {
		clearInterval();
		timer_id = window.setInterval( () => {
			slideshow_obj.slideshow.slide();
		}, slide_interval );
	}

	function setupSlides( els ) {
		for ( let i = 0; i < els.length; i++ ) {
			slide_data.push( createSlideObject( els[i], i ) );
		}
	}
	function createSlideObject( el, index ) {
		if ( el.getAttribute( 'data-slide-type' ) === 'video' ) {
			const video = el.querySelector( 'video' );
			const player = new VideoPlayer( { container: el, video: video, autoplay: index === 0 }, true );
			player.video.addEventListener( 'ended', () => {
				genericSlide();
			}, false );
			player.addCallback( 'error', () => {
				autoplay();
			} );
			player.addCallback( 'durationchange', () => {
				slide_data[index].timer = player.video.duration * 1000;
			} );
			return { item: player, type: 'video', timer: slide_interval };
		}
		return { item: null, type: 'image', timer: slide_interval };
	}
	function genericSlide() {
		if ( slideshow_obj.type === 'Ten4Slideshow' ) {
			slideshow_obj.slideshow.slide();

		} else if ( slideshow_obj.type === 'Swipe' ) {
			slideshow_obj.slideshow.next();
		}
	}
	function autoplay() {
		if ( slideshow_obj.type === 'Ten4Slideshow' ) {
			resetInterval();
		} else if ( slideshow_obj.type === 'Swipe' ) {
			slideshow_obj.slideshow.restart();
		}
	}
	function startSlideshow() {
		if ( slide_data[0].type !== 'video' ) {
			autoplay();
		}
	}
}
