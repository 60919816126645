/* globals imagesLoaded, smoothScroll */

// framework libs
// import './vendor/respond.min.js';
// import './vendor/polyfills/eventlistener.min.js';
// import './vendor/polyfills/classlist.min.js';

// other libs
import './vendor/smooth-scroll.min.js';
import Uncloak from './vendor/uncloak.js';
import imagesLoaded from 'imagesloaded';
// import './vendor/imagesloaded.pkgd.min.js';

// helpers & variables
// import { breakpoints } from '../config.yml';

// modules
import { buildSlideshows } from './modules/build-slideshows.js';
import { LightBox } from './modules/lightbox.js';

buildSlideshows();

// final.js
( () => {
	// Cookie notice
	if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
		document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000;secure';
		document.body.classList.add( 'show-cookie-notice' );
		document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
			document.body.classList.remove( 'show-cookie-notice' );
		} );
	}
} )();

( () => {
	// Uncloak
	new Uncloak( {
		progVars: { m: 80, c: 100 }
	} );
} )();

( () => {
	// Toggle handling
	function handleToggle( toggler ) {
		const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
		return () => {
			for ( let i = 0; i < togglees.length; i += 1 ) {
				if ( togglees[i].classList.contains( 'toggled' ) ) {
					togglees[i].classList.remove( 'toggled' );
					continue;
				}
				togglees[i].classList.add( 'toggled' );
			}
			if ( toggler.classList.contains( 'toggler--a' ) ) {
				toggler.classList.remove( 'toggler--a' );
				toggler.classList.add( 'toggler--b' );
				return;
			}
			toggler.classList.remove( 'toggler--b' );
			toggler.classList.add( 'toggler--a' );
		};
	}
	const togglers = document.querySelectorAll( '.toggler' );
	for ( let i = 0; i < togglers.length; i += 1 ) {
		togglers[i].addEventListener( 'click', handleToggle( togglers[i] ) );
	}
} )();

( () => {
	// Search
	const search = document.querySelector( '.search' );
	const search_toggle = document.querySelector( '.toggler.icon--search' );
	const search_input = document.getElementById( 'js-search-input' );

	search_toggle.addEventListener( 'click', () => {
		window.setTimeout( () => {
			search_input.focus();
		}, 500 );
	} );

	window.addEventListener( 'keyup', ( e ) => {
		if ( e.keyCode === 27 ) {
			search.classList.remove( 'toggled' );
		}
	} );
} )();

( () => {
	// Height match columns
	function resetElementHeights( elements ) {
		for ( let i = 0; i < elements.length; i += 1 ) {
			elements[i].style.height = 'auto';
		}
	}

	function getShortestElement( elements ) {
		let shortest = elements[0];
		for ( let i = 1; i < elements.length; i += 1 ) {
			if ( elements[i].offsetHeight < shortest.offsetHeight ) {
				shortest = elements[i];
			}
		}
		return shortest;
	}

	const elements = document.querySelectorAll( '.heightmatch' );
	const bins = {};
	let bin_id;

	function heightmatchBin( bin_id ) {
		resetElementHeights( bins[bin_id] );
		const shortest = getShortestElement( bins[bin_id] );
		for ( let i = 0; i < bins[bin_id].length; i += 1 ) {
			bins[bin_id][i].style.height = shortest.offsetHeight + 'px';
		}
	}

	function heightmatchAllBins() {
		for ( const bin_id in bins ) {
			if ( !bins.hasOwnProperty( bin_id ) ) {
				continue;
			}
			heightmatchBin( bin_id );
		}
	}

	for ( let i = 0; i < elements.length; i += 1 ) {
		bin_id = elements[i].getAttribute( 'data-heightmatch-bin' );
		if ( !bins.hasOwnProperty( bin_id ) ) {
			bins[bin_id] = [];
		}
		bins[bin_id].push( elements[i] );
	}

	// Not ideal to heightmatch multiple times, after each individual
	// image loads, but I found it was the best way to avoid weird loading
	// issues.
	const body_image_load = imagesLoaded( document.body );
	body_image_load.on( 'progress', () => {
		heightmatchAllBins();
	} );

	window.addEventListener( 'resize', () => {
		window.setTimeout( () => {
			heightmatchAllBins();
		}, 1000 );
	} );

} )();

function getOffsetTop( element ) {
	// Thanks to https://github.com/cferdinandi/smooth-scroll
	let offset = 0;
	if ( element.offsetParent ) {
		do {
			offset += element.offsetTop;
			element = element.offsetParent;
		} while ( element );
	}
	offset = Math.max( offset, 0 );
	return offset;
}

( () => {
	// Smooth Scroll
	function handleSmoothAnchorClick( anchor ) {
		const target_id = anchor.href.split( '#' )[1];
		const target = document.getElementById( target_id );
		return function( event ) {
			event.preventDefault();
			const target_y = getOffsetTop( target );
			smoothScroll.scroll( target_y, 1000 );
			const window_width = document.documentElement.clientWidth || document.body.clientWidth;
			if ( window_width <= 480 ) {
				return;
			}
		};
	}
	const anchors = document.querySelectorAll( '.js-smooth-anchor' );
	for ( let i = 0; i < anchors.length; i += 1 ) {
		anchors[i].addEventListener( 'click', handleSmoothAnchorClick( anchors[i] ) );
	}
} )();

( () => {
	// Hide/reveal scroll to top button
	const button = document.querySelector( '.back-to-top' );
	if ( !button ) {
		return;
	}
	window.addEventListener( 'scroll', () => {
		const scrollPosY = window.pageYOffset || document.body.scrollTop;
		if ( scrollPosY > 300 ) {
			button.classList.add( 'back-to-top--shown' );
		} else if ( scrollPosY <= 300 ) {
			if ( button.classList.contains( 'back-to-top--shown' ) ) {
				button.classList.remove( 'back-to-top--shown' );
			}
		}
	} );
} )();

( () => {
	// Social share popups
	function popUpWindow( url ) {
		const popup_width = 550;
		const popup_height = 420;
		const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
		const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;
		const new_window = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );
		if ( window.focus ) {
			new_window.focus();
		}
		return false;
	}

	function handleSocialShare( share_button ) {
		const url = share_button.getAttribute( 'data-share-url' );
		return ( event ) => {
			event.preventDefault();
			popUpWindow( url );
		};
	}

	const social_share_buttons = document.querySelectorAll( '.social-share' );
	for ( let i = 0; i < social_share_buttons.length; i += 1 ) {
		social_share_buttons[i].addEventListener( 'click', handleSocialShare( social_share_buttons[i] ) );
	}
} )();

new LightBox();
